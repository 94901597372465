













































































































import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { MessageService } from "@/shared/services/message-service";
import incidenciaModule from "@/store/modules/incidencia-module";
import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {
    IncidenciasFormulario: () =>
      import("@/views/proyectos/incidencias/incidenciasFormulario.vue"),
    KanbanList: () => import("@/components/TypeView/Kanban_List.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class IncidenciasLista extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("incidencias", { default: [] })
  incidencias_list!: incidenciaAllDto[];
  @PropSync("id_tarea", { default: -1 }) id_tareasync!: number;

  public show_dialog_incident: boolean = false;
  public id_inicencia: number = -1;

  created() {
    this.GetIncidencias();
  }

  public get inidencias() {
    return incidenciaModule.incidencias_proyecto;
  }

  public GetIncidencias() {
    if (this.id_proyectosync === -1 && this.id_tareasync === -1) {
      return incidenciaModule.getincidencias_proyectos();
    } else if (this.id_tareasync === -1) {
      return incidenciaModule.getincidencias_proyecto(this.id_proyectosync);
    } else {
      return incidenciaModule.getincidencias_tarea(this.id_tareasync);
    }
  }
  editar(obj: incidenciaAllDto) {
    this.id_inicencia = obj.id;
    this.show_dialog_incident = true;
  }

  public close_dialog() {
    this.show_dialog_incident = false;
    this.GetIncidencias().then(
      (x: incidenciaAllDto[]) => (this.incidencias_list = x)
    );
    this.id_inicencia = -1;
  }

  eliminar(obj: incidenciaAllDto) {
    MessageService.confirm(
      "¿Seguro que desea eliminar la incidencia?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          incidenciaModule.eliminarincidencia(obj).then(() => {
            /*this.incidencias_list.splice(
              this.incidencias_list.findIndex((x) => x.id === obj.id),
              1
            );*/

            this.GetIncidencias();
          });
        }
      }
    );
  }
}
